<template>
    <FormDropdownComplex 
    v-model="localValue"  
    :showClear="true" 
    :options="cards" 
    :optionLabel="'text'" 
    :optionValue="'text'" 
    :labelInOption="'text'" 
    :labelInValue="'text'">
        <template #value="slotProps">
            <div style="align-items: center; display: flex; -webkit-box-align: center">
                <img :src="'/images/icons/payment/' + (typeof slotProps.value == 'object' ? slotProps.value.text.toUpperCase() : slotProps.value.toUpperCase()) + '.png'" class="ddl-card-icon" /> {{ typeof slotProps.value == 'object' ? slotProps.value.text.toUpperCase() : slotProps.value.toUpperCase() }}
            </div>
        </template>
        <template #option="slotProps">
            <div style="align-items: center; display: flex; -webkit-box-align: center">
                <img :src="'/images/icons/payment/' + slotProps.option.text.toUpperCase() + '.png'" class="ddl-card-icon" /> {{slotProps.option.text.toUpperCase()}}
            </div>
        </template>
    </FormDropdownComplex>
</template>

<script>
import FormDropdownComplex from "../general/FormDropdownComplex.vue";
export default {
    components: {
        FormDropdownComplex
    },
    props: {
        modelValue: {
            type: [String, Number, null],
            required: true,
        },
    },
    data() {
        return {
            cards: [
                { id: '001', text: 'Visa' },
                { id: '002', text: 'Mastercard' },
                { id: '003', text: 'AmericanExpress' },
            ],
            localValue: this.modelValue,
        }
    },
    methods: {
        onChange(event){
            this.$emit('update:modelValue', event.value);
            this.$emit('change', event.value);
        },
    },
    watch: {
        localValue(newValue) {
            this.$emit("input", newValue);
        },
        modelValue(newValue) {
            this.localValue = newValue;
        },
    }
}
</script>

<style>
    .ddl-card-icon {
        width: 30px;
        margin-right: 5px;
    }
</style>