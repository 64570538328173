import {
    Abstract
} from './Abstract';

export class BWShippingAddress extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Tokenization/ShippingAddress', session);
        this.id_bw_cybersource_customer = null;
        this.is_default = null;
        this.shipTo_firstName = null;
        this.shipTo_lastName = null;
        this.shipTo_company = null;
        this.shipTo_address1 = null;
        this.shipTo_address2 = null;
        this.shipTo_locality = null;
        this.shipTo_administrativeArea = null;
        this.shipTo_postalCode = null;
        this.shipTo_country = null;
        this.shipTo_email = null;
        this.shipTo_phoneNumber = null;
    }
}