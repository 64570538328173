import {
    Abstract
} from './Abstract';

export class BWPaymentInstrument extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Tokenization/PaymentInstrument', session);
        this.id_bw_cybersource_customer = null;
        this.is_default = false;
        this.type = null;
        this.card_expirationMonth = null;
        this.card_expirationYear = null;
        this.card_number = null;
        this.card_type_id = null;
        this.card_type_text = null;
        this.billTo_firstName = null;
        this.billTo_lastName = null;
        this.billTo_company = null;
        this.billTo_address1 = null;
        this.billTo_address2 = null;
        this.billTo_locality = null;
        this.billTo_administrativeArea = null;
        this.billTo_postalCode = null;
        this.billTo_country = null;
        this.billTo_email = null;
        this.billTo_phoneNumber = null;
        this.useAs = "CREDIT";
    }
}