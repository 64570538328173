<template>
    <FormDropdownComplex 
    v-model="localValue" 
    :showClear="true" 
    :disabled="disabled"
    :options="countries" 
    :optionLabel="'name'" 
    :optionValue="'code'"
    :labelInOption="'name'" 
    :labelInValue="'name'">
        <template #value="slotProps">
            <div style="align-items: center; display: flex; -webkit-box-align: center">
                 <img class="ddl-card-icon" src="/images/icons/flags/flag_placeholder.png" :class="'flag flag-' + getFlag(slotProps) " />
                {{ getFlagName(slotProps) }}
            </div>
        </template>
        <template #option="slotProps">
            <div style="align-items: center; display: flex; -webkit-box-align: center">
                <img class="ddl-card-icon" src="/images/icons/flags/flag_placeholder.png" :class="'flag flag-' + slotProps.option.code.toLowerCase()" />
                {{ slotProps.option.name.toUpperCase() }}
            </div>
        </template>
    </FormDropdownComplex>
</template>

<script>
import { countries } from '../../utilities/General';
import FormDropdownComplex from "../general/FormDropdownComplex.vue";
export default {
    components: {
        FormDropdownComplex
    },
    props: {
        modelValue: {
            type: [String, Number, null],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            countries: [],
            localValue: this.modelValue,
        }
    },
    methods: {
        getFlag(slotProps) {
            return typeof slotProps.value == 'object' ? slotProps.value.code.toLowerCase() : slotProps.value.toLowerCase();
        },
        getFlagName(slotProps) { 
            return  typeof slotProps.value == 'object' ?  slotProps.value.name.toUpperCase() : this.countries.find(x => x.code.toLowerCase() == slotProps.value.toLowerCase()).name.toUpperCase();
        },
        onChange(event){
            this.$emit('update:modelValue', event.value);
            this.$emit('change', event.value);
        },
    },
    watch: {
        localValue(newValue) {
            this.$emit("update:modelValue", newValue);
            this.$emit('change', newValue);
        },
        modelValue(newValue) {
            this.localValue = newValue;
        },
    },
    async mounted() {
        this.countries = await countries();
    }
}
</script>

<style>
    .ddl-card-icon {
        width: 30px;
        margin-right: 5px;
    }
</style>