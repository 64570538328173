<template>
    <Fieldset :toggleable="true" legend="Informacion de Pago">
        <Loader v-model="loading" />
        <Toast />
        <Helper v-model="paymentInstrumentHelper" header="Buscar Instrumento de Pago" :style="{ width: '85%' }" :headers="paymentInstrumentHeaders" :rows="paymentInstruments" @selected="selectPaymentInstrument" />
        <BasicFormToolbar :actions="actions" @new="newPaymentInstrument" @save="savePaymentInstrument" @search="paymentInstrumentHelper.visible = true" @delete="deletePaymentInstrument" />
        <div class="p-fluid formgrid grid">
            <FormDropdown label="Tipo de Pago" :optionLabel="'text'" :optionValue="'id'" :options="cardTypes"  v-model="localValue.paymentInformation.card.useAs" :wrapperClass="'field col-10 md:col-6 xl:col-3'" />
            <FormInputSwitch label="Default" :wrapperClass="'col-2 md:col-1 xl:col-1'" v-model="localValue.paymentInformation.paymentType.is_default" />
            <FormInputSwitch label="¿Utilizar los mismos datos de direccion para tarjeta?" :wrapperClass="'col-12 md:col-5 xl:col-8'" v-model="localValue.paymentInformation.paymentType.is_using_shipping" />
            <FormInputText :type="'mask'" mask="9999999999999999" placeholder="XXXXXXXXXXXXXXXX" wrapperClass="field col-12 md:col-6 xl:col-3" label="Numero Tarjeta" v-model="localValue.paymentInformation.card.number" :valid="paymentInstrumentValidate.validations.card_number" />
            <BankCard v-model="localValue.paymentInformation.card.type" wrapperClass="field col-12 xl:col-5" label="Tipo de Tarjeta" :valid="paymentInstrumentValidate.validations.card_type_id" />
            <FormCalendar  :valid="paymentInstrumentValidate.validations.card_expirationMonth" view="month" :dateFormat="'mm/yy'" v-model="localValue.paymentInformation.card.expiration" label="Expiracion" :wrapperClass="'field col-6 md:col-6 xl:col-4'" />
            <!-- ESTOY CASI SEGURO QUE NO SE NECESITA -->
            <!-- <FormInputText class="field col-6 md:col-6 xl:col-2" label="CVV" :type="'mask'" mask="999" v-model="localValue.paymentInformation.card.securityCode" placeholder="***" :valid="paymentInstrumentValidate.validations.card_cvv" /> -->
            <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                <Panel v-if="!localValue.paymentInformation.paymentType.is_using_shipping" header="Informacion de Cobro">
                    <div class="p-fluid formgrid grid">
                        <FormInputText wrapperClass="field col-12 xl:col-6" label="Nombre" v-model="localValue.orderInformationBillToExtra.firstName" :disabled="paymentInstrument.id != null" :valid="paymentInstrumentBillToValidate.validations.billTo_firstName" />
                        <FormInputText wrapperClass="field col-12 xl:col-6" label="Apellido" v-model="localValue.orderInformationBillToExtra.lastName" :disabled="paymentInstrument.id != null" :valid="paymentInstrumentBillToValidate.validations.billTo_lastName" />
                        <FormInputText wrapperClass="field col-12 xl:col-4" label="Direccion #1" v-model="localValue.orderInformationBillToExtra.address1" :disabled="paymentInstrument.id != null" :valid="paymentInstrumentBillToValidate.validations.billTo_address1" />
                        <FormInputText wrapperClass="field col-12 xl:col-4" label="Direccion #2" v-model="localValue.orderInformationBillToExtra.address2" :disabled="paymentInstrument.id != null" />
                        <FormInputText wrapperClass="field col-12 xl:col-4" label="Codigo Postal" v-model="localValue.orderInformationBillToExtra.postalCode" :disabled="paymentInstrument.id != null" />
                        <Country wrapperClass="field col-12 xl:col-4" label="Pais" v-model="localValue.orderInformationBillToExtra.country" />
                        <FormInputText wrapperClass="field col-12 xl:col-4" label="Estado" v-model="localValue.orderInformationBillToExtra.district" :disabled="paymentInstrument.id != null" />
                        <FormInputText wrapperClass="field col-12 xl:col-4" label="Ciudad" v-model="localValue.orderInformationBillToExtra.locality" :disabled="paymentInstrument.id != null" />
                    </div>
                </Panel>
            </FadeInOut>
        </div>
    </Fieldset>
</template>

<script>
import Loader from "../../../../components/general/Loader.vue";
import Session from "../../../../mixins/rootSessionMixin";
import Helper from "../../../../components/general/HelperDialog.vue";
import BasicFormToolbar from "../../../../components/general/BasicFormToolbar.vue";
import FormDropdown from "../../../../components/general/FormDropdown.vue";
import FormInputSwitch from "../../../../components/general/FormInputSwitch.vue";
import FormCalendar from "../../../../components/general/FormCalendar.vue";
import FormInputText from "../../../../components/general/FormInputText.vue";
import Country from "../../../../components/basic data/Country.vue";
import BankCard from "../../../../components/basic data/BankCard.vue";
import { BWPaymentInstrument } from '../../../../models/custom/business_wallet/BWPaymentInstrument';


import moment from 'moment';
import { ErrorToast, fillObject, HeaderGrid, Rule, Toast, validate, countries } from '../../../../utilities/General';
export default {
    mixins: [Session],
    expose: ['selectPaymentInstrument'],
    emits: ['update:modelValue'],
    props: {
        paymentInstruments: { 
            type: Array,
            default: () => []
        },
        customer: { 
            type: Object,
            default: () => {}
        },
        modelValue: {
            required: true,
        },
        actions: {
            type: Array,
            default: () => ['new', 'save', 'search', 'delete']
        },
          is_shipping_save: {
            type: Boolean,
            default:false
        }
    },
    components: {
        BankCard, Country, Loader, Helper, FormDropdown,BasicFormToolbar, FormInputSwitch, FormCalendar, FormInputText
    },
    data() {
        return {
            cards: [
                { id: '001', text: 'Visa' },
                { id: '002', text: 'Mastercard' },
                { id: '003', text: 'AmericanExpress' },
            ],
            paymentInstrumentBillToRules: [
                new Rule({ name: 'billTo_firstName' }), 
                new Rule({ name: 'billTo_lastName' }), 
                new Rule({ name: 'billTo_address1' }), 
                new Rule({ name: 'billTo_country' })],
            paymentInstrumentRules: [
                new Rule({ name: 'card_type_id' }),
                new Rule({ name: 'card_expirationYear' }),
                new Rule({ name: 'card_expirationMonth' }),
                new Rule({ name: 'card_number' }),
                // new Rule({ name: 'card_cvv' }),
            ],
            paymentInstrumentHeaders: [
                new HeaderGrid('Tipo', 'type'),
                new HeaderGrid('Sub Tipo', 'card_type_text'),
                new HeaderGrid('Tarjeta', 'card_number'),
                new HeaderGrid('Mes', 'card_expirationMonth'),
                new HeaderGrid('Año', 'card_expirationYear'),
                new HeaderGrid('Predeterminada', 'is_default', { type: 'boolean'}),
            ],
            paymentInstrumentHelper: {
                visible: false,
            },
            loading: false,
            paymentInstrument: new BWPaymentInstrument(),
          
            localValue: this.modelValue,
            cardTypes: [
                { id: 'credit', text: 'Tarjeta de Credito' },
                { id: 'pinless debit', text: 'Tarjeta de Debito (PINless)' },
            ],
            paymentInstrumentBillToValidate: {
                valid: false,
                validations: {
                    billTo_firstName: null,
                    billTo_lastName: null,
                    billTo_address1: null,
                    billTo_country: null,
                },
            },
            shippingValidate: {
                valid: false,
                validations: {
                    shipTo_firstName: null,
                    shipTo_lastName: null,
                    shipTo_address1: null,
                    shipTo_country: null,
                },
            },
            paymentInstrumentValidate: {
                valid: false,
                validations: {
                    card_type_id: null,
                    card_expirationYear: null,
                    card_expirationMonth: null,
                    card_number: null,
                    // card_cvv: null,
                },
            },
        }
    },
    watch: {
        localValue(newValue) {
            //Creo que el input ya no es
            this.$emit('update:modelValue', newValue);
        },
        modelValue(newValue) {
            this.localValue = newValue;
        },
    },
     async mounted() {
        try {
            this.countries = await countries();
        } catch (error) {
            console.log(error);
        }
       

    },
    methods: {
        selectPaymentInstrument(paymentInstrument) {
            this.paymentInstrument = fillObject(this.paymentInstrument, paymentInstrument);
            this.localValue.paymentInformation.paymentType.is_using_shipping = false;
            this.localValue.paymentInformation.paymentType.is_default = this.paymentInstrument.is_default;
            this.localValue.paymentInformation.card.number = this.paymentInstrument.card_number;
            // this.localValue.paymentInformation.card.securityCode = this.paymentInstrument.card_cvv;
            this.localValue.paymentInformation.card.type = this.cards.find((x) => x.id == this.paymentInstrument.card_type_id);
            this.localValue.paymentInformation.paymentType.type = this.paymentInstrument.type;
            this.localValue.paymentInformation.card.useAs = this.paymentInstrument.useAs ?? 'credit';
            let date = moment(this.paymentInstrument.card_expirationMonth + '/01/' + this.paymentInstrument.card_expirationYear);
            this.localValue.paymentInformation.card.expiration = date.toDate();
            this.localValue.paymentInformation.card.expirationYear = this.paymentInstrument.card_expirationYear;
            this.localValue.paymentInformation.card.expirationMonth = this.paymentInstrument.card_expirationMonth;
            
            if(this.is_shipping_save==true){     
            this.localValue.orderInformationBillToExtra.firstName= this.paymentInstrument.billTo_firstName; 
            this.localValue.orderInformationBillToExtra.lastName= this.paymentInstrument.billTo_lastName;
            this.localValue.orderInformationBillToExtra.address1= this.paymentInstrument.billTo_address1;
            this.localValue.orderInformationBillToExtra.address2= this.paymentInstrument.billTo_address2;
            this.localValue.orderInformationBillToExtra.locality= this.paymentInstrument.billTo_locality;
            this.localValue.orderInformationBillToExtra.postalCode= this.paymentInstrument.billTo_postalCode;
            this.localValue.orderInformationBillToExtra.district= this.paymentInstrument.billTo_administrativeArea;
            this.localValue.orderInformationBillToExtra.country= this.countries.find((country) => country.code == this.paymentInstrument.billTo_country);
            }
        },
        newPaymentInstrument() {
            this.paymentInstrument = new BWPaymentInstrument(this.session);
            this.localValue.paymentInformation.paymentType.type = null;
            this.localValue.paymentInformation.card.expiration = null;
            this.localValue.paymentInformation.card.expirationMonth = null;
            this.localValue.paymentInformation.card.expirationYear = null;
            this.localValue.paymentInformation.card.type = null;
            this.localValue.paymentInformation.card.number = null;
            // this.localValue.paymentInformation.card.securityCode = null;
            this.localValue.paymentInformation.paymentType.name = null;
            this.localValue.paymentInformation.paymentType.is_using_shipping = true;
            this.localValue.paymentInformation.paymentType.is_default = false;
            this.localValue.paymentInformation.card.useAs = 'credit';
        },
         async savePaymentInstrument() {
            try {
                if (!this.customer.id) 
                    throw 'No existe un cliente para este tipo de pago, favor de selecciona uno o crearlo';
                let defaults = this.customer.paymentInstruments.filter(x => x.is_default == true);
                if (defaults.length == 0) 
                    this.localValue.paymentInformation.paymentType.is_default = true;
                if (this.paymentInstrument.id) 
                    throw 'No se puede editar un instrumento de pago, favor de eliminarlo y crear otro';
                this.loading = true;
                let date = moment(this.localValue.paymentInformation.card.expiration).format('DD/MM/YYYY');
                this.paymentInstrument = new BWPaymentInstrument(this.session);
                this.paymentInstrument.type = this.localValue.paymentInformation.paymentType.type;
                this.paymentInstrument.is_default = this.localValue.paymentInformation.paymentType.is_default;
                this.paymentInstrument.id_bw_cybersource_customer = this.customer.id;
                this.paymentInstrument.useAs = this.localValue.paymentInformation.card.useAs ?? 'credit';
                this.paymentInstrument.is_default = this.localValue.paymentInformation.paymentType.is_default;
                this.paymentInstrument.card_expirationMonth = this.localValue.paymentInformation.card.expiration ? date.split('/')[1] : null;
                this.paymentInstrument.card_expirationYear = this.localValue.paymentInformation.card.expiration ? date.split('/')[2] : null;
                this.paymentInstrument.card_number = this.localValue.paymentInformation.card.number;
                // this.paymentInstrument.card_cvv = this.localValue.paymentInformation.card.securityCode;
                this.paymentInstrument.card_type_id = this.localValue.paymentInformation.card.type ? this.localValue.paymentInformation.card.type.id : null;
                this.paymentInstrument.card_type_text = this.localValue.paymentInformation.card.type ? this.localValue.paymentInformation.card.type.text : null;
                this.paymentInstrument.billTo_firstName = this.localValue.paymentInformation.paymentType.is_using_shipping
                    ? this.localValue.orderInformationBillTo.firstName
                    : this.localValue.orderInformationBillToExtra.firstName;
                this.paymentInstrument.billTo_email = this.localValue.paymentInformation.paymentType.is_using_shipping ? this.localValue.orderInformationBillTo.email : this.localValue.orderInformationBillToExtra.email;
                this.paymentInstrument.billTo_phoneNumber = this.localValue.paymentInformation.paymentType.is_using_shipping
                    ? this.localValue.orderInformationBillTo.phoneNumber
                    : this.localValue.orderInformationBillToExtra.phoneNumber;
                this.paymentInstrument.billTo_lastName = this.localValue.paymentInformation.paymentType.is_using_shipping ? this.localValue.orderInformationBillTo.lastName : this.localValue.orderInformationBillToExtra.lastName;
                this.paymentInstrument.billTo_address1 = this.localValue.paymentInformation.paymentType.is_using_shipping ? this.localValue.orderInformationBillTo.address1 : this.localValue.orderInformationBillToExtra.address1;
                this.paymentInstrument.billTo_address2 = this.localValue.paymentInformation.paymentType.is_using_shipping ? this.localValue.orderInformationBillTo.address2 : this.localValue.orderInformationBillToExtra.address2;
                this.paymentInstrument.billTo_locality = this.localValue.paymentInformation.paymentType.is_using_shipping ? this.localValue.orderInformationBillTo.locality : this.localValue.orderInformationBillToExtra.locality;
                this.paymentInstrument.billTo_postalCode = this.localValue.paymentInformation.paymentType.is_using_shipping
                    ? this.localValue.orderInformationBillTo.postalCode
                    : this.localValue.orderInformationBillToExtra.postalCode;
                this.paymentInstrument.billTo_administrativeArea = this.localValue.paymentInformation.paymentType.is_using_shipping
                    ? this.localValue.orderInformationBillTo.district
                    : this.localValue.orderInformationBillToExtra.district;
                this.paymentInstrument.billTo_country = this.localValue.paymentInformation.paymentType.is_using_shipping
                    ? this.localValue.orderInformationBillTo.country
                    : this.localValue.orderInformationBillToExtra.country;
                if (typeof this.paymentInstrument.billTo_country == "object") {
                    this.paymentInstrument.billTo_country = this.paymentInstrument.billTo_country.code;
                }
                this.paymentInstrumentValidate = validate(this.paymentInstrument, this.paymentInstrumentRules);
                if (!this.paymentInstrumentValidate.valid) throw 'Favor de llenar los campos';
                this.paymentInstrumentBillToValidate = validate(this.paymentInstrument, this.paymentInstrumentBillToRules);
                if (this.localValue.paymentInformation.paymentType.is_using_shipping) {
                    //* Validamos el Shipping
                    if (!this.paymentInstrumentBillToValidate.valid) {
                        console.log(this.paymentInstrumentBillToValidate);
                        this.shippingValidate.validations.shipTo_firstName = this.paymentInstrumentBillToValidate.validations.billTo_firstName;
                        this.shippingValidate.validations.shipTo_lastName = this.paymentInstrumentBillToValidate.validations.billTo_lastName;
                        this.shippingValidate.validations.shipTo_address1 = this.paymentInstrumentBillToValidate.validations.billTo_address1;
                        this.shippingValidate.validations.shipTo_country = this.paymentInstrumentBillToValidate.validations.billTo_country;
                        throw 'Favor de llenar los campos';
                    }
                } else {
                    //* Validamos la direcion de la tarjeta
                    this.shippingValidate.validations.shipTo_firstName = true;
                    this.shippingValidate.validations.shipTo_lastName = true;
                    this.shippingValidate.validations.shipTo_address1 = true;
                    this.shippingValidate.validations.shipTo_country = true;
                    if (!this.paymentInstrumentBillToValidate.valid) throw 'Favor de llenar los campos';
                }

                    // this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.push(newShippingAddress);
                    // this.shippingAddress.id = newShippingAddress.id;
                if (!this.paymentInstrument.id) {
                    let newPaymentInstrument = await this.paymentInstrument.save();
                    if (newPaymentInstrument.is_default){
                        this.$emit("is-new-default-payment", newPaymentInstrument);
                    } 
                    this.$emit("created", newPaymentInstrument);
                    
                    this.paymentInstrument.id = newPaymentInstrument.id;

                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion de forma de pago guardada con exito',
                        })
                    );
                }
            } catch (error) {
                console.log('error');
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deletePaymentInstrument() {
            try {
                this.loading = true;
                this.paymentInstrument.id_company = this.session.company;
                this.paymentInstrument.id_branch = this.session.branch; 
                await this.paymentInstrument.delete();
                this.$emit('deleted-payment', this.paymentInstrument);
                this.localValue.paymentInformation.type = null;
                this.newPaymentInstrument();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>
<style>
</style>